<template>
  <v-dialog
    v-model="createSponsorModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Cadastrar Patrocinador
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="mx-5 mt-5"
          @submit.prevent="createSponsor()"
        >
          <v-text-field
            v-model="sponsor.name"
            :rules="[rules.required]"
            outlined
            required
            label="Nome *"
          />

          <image-uploader
            title="Clique aqui para enviar a logo do patrocinador"
            subtitle="Tamanho recomendado: 450 de largura por 200 pixels de altura"
            :saving="saving"
            :fullWidth="true"
            previewWidth="450px"
            previewHeight="200px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="createSponsor()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    ImageUploader: () => import('@/components/ImageUploader.vue')
  },
  data () {
    return {
      saving: false,
      valid: true,
      image: null,
      sponsor: {
        name: '',
        imageType: ''
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      }
    }
  },
  computed: {
    createSponsorModal: {
      get () {
        return this.$store.state.createSponsorModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createSponsorModal',
          value: val
        })
      }
    }
  },
  watch: {
    createSponsorModal (val) {
      if (!val) {
        this.sponsor = {
          name: '',
          imageType: ''
        }
      }
    }
  },
  methods: {
    createSponsor () {
      if (this.$refs.form.validate()) {
        if (!this.sponsor.imageType) {
          this.$toast.error('É necessário enviar a logo do patrocinador.')
          return
        }
        this.saving = true
        this.$http.post('/sponsors', this.sponsor)
          .then((res) => {
            const { data } = res
            if (data.putUrl) {
              this.uploadImage(data.putUrl, this.image, this.success, this.error)
            } else {
              this.success()
            }
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    },
    success () {
      this.saving = false
      this.createSponsorModal = false
      this.$emit('created')
      this.$toast.success('Cadastro realizado com sucesso.')
    },
    error () {
      this.saving = false
      this.createSponsorModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    handleImage (file) {
      this.sponsor.imageType = file.type
      this.image = file
    },
    handleRemoveImage () {
      this.sponsor.imageType = undefined
      this.image = null
    }
  }
}
</script>
